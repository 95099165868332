html,
body {
  margin: 0;
  padding: 0;
  border: 0;

  width: 100%;
  height: 100%;

  font-family: 'Poppins', sans-serif;
  font-size: 20px;

  color: white;
  overflow: hidden;
}

::selection {
  background-color: rgba(255, 255, 255, 0.4);
}

body {
  width: 100%;
  height: 100%;

  overflow: scroll;
  display: flex;
  align-items: safe center;
  justify-content: safe center;

  background-image: radial-gradient(transparent, rgba(0, 0, 0, 0.8)),
    url(./assets/background.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

main {
  padding: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1em;
}

section {
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2rem);
}

#logo {
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 2.5em;
  line-height: 0.9em;

  padding: 0.5em 0.75em;
}

#logo img {
  height: 3.5em;
  margin-right: 0.5em;
}

#description {
  text-align: center;
  padding: 1em 1.5em;
}

nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

@media only screen and (max-width: 800px) {
  html,
  body {
    font-size: 14px;
  }
}

nav > a {
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(2em);

  color: white;
  text-decoration: underline dotted;

  padding: 0.5em 1em;
}
