html, body {
  color: #fff;
  border: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  overflow: hidden;
}

::selection {
  background-color: #fff6;
}

body {
  background-image: radial-gradient(#0000, #000c), url("background.64bbd365.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: safe center;
  align-items: safe center;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: scroll;
}

main {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
  padding: 1em;
  display: flex;
}

section {
  -webkit-backdrop-filter: blur(2rem);
  backdrop-filter: blur(2rem);
  background-color: #fff3;
}

#logo {
  justify-content: center;
  align-items: center;
  padding: .5em .75em;
  font-size: 2.5em;
  line-height: .9em;
  display: flex;
}

#logo img {
  height: 3.5em;
  margin-right: .5em;
}

#description {
  text-align: center;
  padding: 1em 1.5em;
}

nav {
  justify-content: center;
  align-items: center;
  gap: 1em;
  display: flex;
}

@media only screen and (max-width: 800px) {
  html, body {
    font-size: 14px;
  }
}

nav > a {
  -webkit-backdrop-filter: blur(2em);
  backdrop-filter: blur(2em);
  color: #fff;
  background-color: #fff3;
  padding: .5em 1em;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

/*# sourceMappingURL=index.f28b00ab.css.map */
